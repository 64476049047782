/* You can add global styles to this file, and also import other style files */
/* for phone control */
@import 'intl-tel-input/build/css/intlTelInput.css';
/* core theme files for styling */
@import '@kehe/phoenix-theme/kehe-theme/bootstrap/_index.scss';
@import '@kehe/phoenix-theme/kehe-theme/kehe-theme.scss';

.iti__flag {background-image: url("~intl-tel-input/build/img/flags.png");}

.todo {
  background-color: #ffffcc;
  border: 1px solid yellow;
  border-radius: 15px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

body {
  height: 100%;
}

app-root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

h3 {
  border-bottom: none;
}

html,
body {
  -webkit-overflow-scrolling: touch;
}

.navbar-toggler {
  padding-left: 16px;
  padding-right: 16px;
}

.fltLt {
  float: left;
}

.fltRt {
  float: right;
}

.readonly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.loadmore {
  min-width: 130px;
}

/* CSS for feedback button */
.feedback-container {
  width: 100%;
  text-align: center;
}

/* Overrides for item-list-add-to-cart */
.quantityTextBox {
  text-align: center;
}

.quantityTextBoxEdit {
  text-align: center;
  height: 30px !important;
}

/* global styles for backdrops at modal workflow home page */
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
  border: 1px solid $gray-50;
  background-color: rgba(0, 0, 0, 0.3);
}

.status div:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10ch;
}

.gap20bottom {
  margin-bottom: 20px;
}

/* Kendo Toast styles */
kendo-notification-container {
  div > kendo-notification > div {
    div > app-add-line-item-toast > div > div.img-container {
      width: 85px;
      height: 85px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img.kehe-toast-image {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
  }
}


.toast-link {
  text-decoration: underline !important;
}

/* Toast styles */
#toast-container > div.toast-custom {
  padding: 5px;
  color: $white;
}

#toast-container > div.toast-custom .toast-close-button {
  color: $white !important;
}

.toast-top-right {
  top: 120px;
  left: 10px;
  right: inherit;
}

#toast-container > div {
  -webkit-box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.5);
  padding: 10px 10px 10px 10px;
  width: calc(100vw - 20px);
}

#toast-container .product-image-container {
  width: 50px;
  height: 50px;
  float: left;
  margin-right: 5px;
}

#toast-container .product-image {
  max-width: 50px;
  max-height: 50px;
}

#toast-container .text-container {
  color: $white;
}

#toast-container .order-info {
  font-size:$base-font-size;
  color: $white;
}

#toast-container .order-info-strong {
  font-weight: $semi-bold;
  font-size: $base-font-size;
  color: $white;
}

#toast-container .order-info-view-order {
  margin: 10px 0px 5px 0px;
}


.toast {
  background-color: $black;
  border: 1px solid $gray-50;
}

.toast-close-button {
  right: 0em;
  top: -0.2em;
  color: $white;
}

button.toast-close-button {
  color: $white;
}

@media (max-width: 480px) and (min-width: 241px) {
  #toast-container .toast-close-button {
    right: 0em;
    top: -0.2em;
    color: $white;
  }

  button.toast-close-button {
    color: $white;
  }
}

@media screen and (min-width: 768px) {
  #toast-container > div.toast-custom {
    padding: 5px;
    color: $text-dark;
  }

  #toast-container > div.toast-custom .toast-close-button {
    color: $teal-50 !important;
  }

  #toast-container > div {
    -webkit-box-shadow: 3px 3px 7px 0px $gray-10;
    box-shadow: 3px 3px 7px 0px $gray-10;
    padding: 5px 5px 5px 5px;
    width: 300px;
  }

  #toast-container > :hover {
    -webkit-box-shadow: 3px 3px 7px 0px $gray-50;
    box-shadow: 3px 3px 7px 0px $gray-50;
  }

  .toast-top-right {
    right: 16px;
    left: inherit;
  }

  #toast-container .product-image {
    max-width: 50px;
    max-height: 50px;
  }

  #toast-container .text-container {
    color: $text-dark;
  }

  #toast-container .order-info {
    font-size: $font-size-small;
    color: $text-dark;
  }

  #toast-container .order-info-strong {
    font-weight: $semi-bold;
    font-size: $font-size-small;
    color: $text-dark;
  }

  #toast-container .order-info-view-order {
    margin: 10px 0px 5px 0px;
  }

  #toast-container .order-info-view-order-button {
    border: 1px solid $teal-50;
    color: $teal-50;
    padding: 4px;
    font-size: $font-size-footnote;
  }

  .toast {
    background-color: $white;
    border: 1px solid $gray-50;
  }

  .toast-close-button {
    right: 0em;
    top: -0.25em;
  }

  .toast-close-button:hover {
    color: $teal-50;
  }

  button.toast-close-button {
    color: $teal-50;
  }
}

/* Data grid on order detail page */
.d-grid-body {
  min-height: 30px !important;
}
.dg-order-detail-item {
  min-width: 25%;
}
.dg-order-detail-upc {
  text-align: center;
  width: 15%;
}
.dg-order-detail-casepack {
  text-align: center;
  width: 10%;
}
.dg-order-detail-quantity {
  text-align: right;
  width: 15%;
}
.dg-order-detail-unitprice {
  text-align: right;
  width: 15%;
}
.dg-order-detail-totalprice {
  text-align: right;
  width: 15%;
}
.dg-order-detail-delete {
  width: 5%;
  text-align: center;
  cursor: pointer;
}
.dg-order-detail-delete * {
  pointer-events: none;
}
/* new grid extra classes */
.error-message-hover {
  color: $red-50;
  margin-left: 15px;
}

.success-message-hover {
  color: rgb(22, 207, 15);
  margin-left: 15px;
}

.document-searchbar {
  text-align: right !important;
  max-width: 1px;
}

/* Document Grid */

.document-grid-item-selected {
  width: 2%;
  text-align: center !important;
}

.document-grid-item-selected > * {
  pointer-events: none;
}

.document-grid-item-file-type-icon {
  width: 3%;
  text-align: center !important;
}

.document-grid-item-file-type-icon > * {
  pointer-events: none;
}

.document-grid-item-title {
  width: 20%;
}

.document-grid-item-title span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.document-grid-item-publication-type {
  width: 10%;
}

.document-grid-item-file-ext {
  width: 5%;
  text-align: left !important;
}

.document-grid-item-modified {
  width: 5%;
}

.document-grid-item-file-size {
  width: 5%;
  text-align: right !important;
}

@media screen and (min-width: 768px) {
  .document-grid-item-selected {
    width: 2%;
  }

  .document-grid-item-file-type-icon {
    width: 3%;
  }

  .document-grid-item-title {
    width: 20%;
  }

  .document-grid-item-publication-type {
    width: 10%;
  }

  .document-grid-item-file-ext {
    width: 5%;
  }

  .document-grid-item-modified {
    width: 5%;
  }

  .document-grid-item-file-size {
    width: 5%;
    text-align: right !important;
  }
}

.spinner:before {
  z-index: 9999;
  display: none;
}

/* Styles for invoice list data grid */
.dg-invoice-list-number {
  width: 20%;
  cursor: pointer;
}

.dg-invoice-list-number-link {
  pointer-events: none;
}

.dg-invoice-list-date {
  width: 20%;
  text-align: center;
}

.dg-invoice-list-po {
  width: 20%;
}

.dg-invoice-list-totalvalue {
  text-align: right;
  width: 20%;
}

.dg-invoice-list-lines {
  text-align: right;
  width: 20%;
}

.dg-invoice-download {
  width: 5%;
  cursor: pointer;
  text-align: center;
}
.dg-invoice-download * {
  pointer-events: none;
}

/* Override for button component */
.short-btn {
  padding: 1px !important;
}

/* Override for navbar height */
@media screen and (min-width: 992px) {
  .navbar {
    height: 40px;
  }
}

#btn-browse-brands {
  width: 175px;
  height: 50px;
  font-family: $OpenSansFontFamily;
  color: $text-dark;
  font-size: $font-size-large;
}

#brands div.kehe-modal {
  width: 75%;
}

.kehe-tooltip-show{
  z-index: 10000 !important;
}

#brands div.kehe-modal div.content {
  height: 500px;
  padding-top: 0;
}

.open-modal-link {
  color: $teal-75;
  cursor: pointer;
}

.open-modal-link:hover {
  color: $teal-100;
}

.hidden {
  display: none;
}

#export-price-dc div.kehe-modal div.content {
  width: 500px;
  height: 171px;
}

#export-price-customer div.kehe-modal div.content {
  width: 500px;
  height: 499px;
}

/* overrides for supplier general form */
.general-form-container {
  .form-group {
    margin-bottom: 0px;
  }
  kehe-address div[formgroupname] {
    margin-bottom: 16px;
  }
  kehe-address .form-group {
    margin-bottom: 16px;
  }
  app-contact-card .form-group {
    margin-bottom: 16px;
  }
}

.main-container > app-dcs-page,
.main-container > app-document-list-page,
.main-container > app-supplier-detail-page,
.main-container > app-broker-detail-page,
.main-container > app-customer-page,
.main-container > app-customer-detail-page,
.main-container > app-show-detail-page {
  flex: 1;
  display: block;
}

.product-grid-col-stacked {
  text-transform: capitalize;
}

.product-grid-col-plain {
  text-transform: capitalize;
}

.product-grid-col-override {
  color: $text-dark;
  text-decoration: underline $white;
  text-transform: capitalize;
  font-weight: $regular;
  cursor: none;
}

.product-grid-item-checked {
  width: 2%;
  text-align: center !important;
}

.product-grid-item-checked > * {
  pointer-events: none;
}

@media screen and (min-width: 768px) {
  .product-grid-item-checked {
    width: 2%;
  }
}
/* override for chips placement for supplier-list chips */
app-supplier-page .chips-panel.opened-height,
app-customer-page .chips-panel.opened-height {
  align-items: flex-end !important;
}

app-customer-page app-slide-panel .date-input-wrap,
app-customer-page app-slide-panel .dateInput {
  width: 100% !important;
}

.dc-supplier-grid-po-trans {
  word-wrap: break-word;
}

.broker-list-column-ebn {
  width: 18%;
  min-width: 100px;
}

.edit-mode {
  .has-danger {
    input {
      &.error,
      &.error:focus {
        border-color: $red-50 !important;
        outline-offset: 0px !important;
        outline: none !important;
        box-shadow: none;
      }
    }
    .form-control-feedback p {
      text-transform: none;
    }
  }
}

.show-details-component {
  .suppliers-tab {
    .fa-trash-alt:hover {
      color: $red-50 !important;
    }

    .show-supplier-list-checkbox,
    .show-supplier-list-delete {
      width: 42px;

      .g-grid-icons-button {
        justify-content: center;
      }
    }

    .show-supplier-list-date {
      width: 230px;
    }

    .show-supplier-list-count {
      width: 105px;
      text-align: center !important;
    }

    .show-suppliers-grid {
      th:has(input) {
        padding: 8px !important;
      }
      td:has(input) {
        padding: 8px !important;
      }
    }
  }

  .retailers-tab {
    .fa-trash-alt:hover {
      color: $red-50 !important;
    }

    .show-retailer-list-checkbox,
    .show-retailer-list-delete {
      width: 42px;

      .g-grid-icons-button {
        justify-content: center;
      }
    }

    .show-retailer-list-date {
      width: 230px;
    }

    .show-retailer-list-count {
      width: 105px;
      text-align: center !important;
    }
  }
}

.form-group app-tooltip {
  color: $blue-50;
  margin: 0 8px;
}

.product-summary .summary-info.form-group .form-group {
  margin-bottom: 0;
}

[isDisabled] .selectTextContainerDesktop {
  background: $gray-10 !important;
  border: 1px solid $gray-25 !important;

  .chevron {
    display: none;
  }
}

.score-card .form-group {
  margin-bottom: 0px;
}

.upc .input-group {
  width: 60%;
}

.ean .input-group {
  width: 60%;
}

.price-group .input-group {
  width: 50%;
}

.form-group {
  input:not(.k-input-inner):not(.k-input):not(.k-radio) {
    &:disabled {
      background: $gray-0 !important;
      border: 1px solid $gray-10 !important;
      color: $gray-50;
    }
  }
}

.prod-dimen-container kendo-numerictextbox input {
  text-align: center;
}

app-textbox + label.error-message-text,
suggestive-search-v2 + label.error-message-text {
  margin-bottom: 1rem;
}


.form-checkbox-margin {
  margin-bottom: 8px;
  margin-left: 2px;
}

.name .form-group,
.case-gtin .form-group,
.ean .form-group,
.shelf-life-in-days .form-group,
.supplier-tie-hi .form-group,
.shelf-label-name .form-group,
.uom-size .form-group,
.brand .form-group,
.category-manager .form-group,
.c-of-origin .form-group,
.product-class .form-group,
.level1-code .form-group,
.level2-code .form-group,
.level2-code .error-message-text,
.level3-code .form-group,
.level4-code .form-group,
.level4-code .error-message-text,
.level5-code .form-group,
.prod-dimen-container .form-group,
.level5-code .error-message-text {
  margin-bottom: 0;
}

.is-inner-pk,
.cool-c,
.level3-code,
.level5-code {
  margin-top: 10px;
}

.prod-dimen-container .textboxComponent {
  text-align: center;
}

.add-supplier-modal .alert-bar {
  margin: 0px !important;
}

#ddMinimumPromotion .dropdownContainer,
#ddPromoPeriod .dropdownContainer,
#ddPackaging .dropdownContainer,
#ddDivision .dropdownContainer {
  z-index: 1000;
}

.add-show-customers-modal-container {
  .kehe-modal {
    overflow: visible !important;
    .content {
      overflow: inherit !important;
    }
  }
}

.modal-custom-footer ::ng-deep .btn-delete {
  border: 2px solid $red-10 !important;
  color: $red-75 !important;

  &:hover {
    background-color: $red-0 !important;
    border: 2px solid $red-10 !important;
    color: $red-75 !important;
  }

  &:focus {
    box-shadow: 0 0 2px $red-25 !important;
    border: 2px solid $red-25 !important;
    color: $red-100 !important;
    background-color: $red-10 !important;
  }

  &:active {
    box-shadow: 0 0 2px $red-25 !important;
    border: 2px solid $red-25 !important;
    color: $red-100 !important;
    background-color: $red-10 !important;
  }

  &.disabled,
  &:disabled {
    background-color: $gray-25 !important;
    border: 2px solid $gray-25 !important;
    color: $white !important;
  }
}

.modal-btn-spacing {
  margin: 0 0 0 8px;
}

.textbox-icon-addon {
  z-index: 15 !important;
}
.tooltip {
  text-align: left !important;
}

.grid-edit-icon-cell {
  width: 41px;
  text-align: center !important;
  padding: 0 0 0 15px !important;
}

.po-trans-button-postions {
  max-width: 49.2px;
  margin: 18px 0 0 0;
}

// begin detail global styles
.dropdown-tab-container {
  padding-top: $spacer;
  padding-bottom: $spacer;
}

.kehe-data-section {
  div.row {
    margin-bottom: $spacer;
  }
  div.row:last-child {
    margin-bottom: 0;
  }
}

hr {
  margin-top: $spacer * 2;
  margin-bottom: $spacer * 2;
  border-top: 1px solid $gray-25;
}

.info-tooltip {
  color: $blue-50;
  margin-left: 0.25rem;
}
// end detail global styles

app-product-page {
  overflow: hidden;
}

app-option-menu.block {
  app-button {
    width: 100%;
    button {
      width: 100%;
    }
  }
}
.attributes-tab {
  label {
    position: relative !important;
  }
  .attributes-section .k-label {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
    font-weight: 600 !important;
    font-size: 12px;
  }
}
.search-shipper-product .kehe-chips-container {
  display: none;
}

#accountinvoicesmodal .body {
  overflow-y: unset;
}

.has-danger input {
  background-color: $red-0 !important;
  border: 1px solid $red-50 !important;
}

.kehe-pack-text .k-input {
  text-align: center !important;
}

a.link-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

app-upload-documents-modal, app-delete-product-modal, app-add-name-value-pair-modal, app-scheduled-changes-view,
app-mass-upload-edit-modal {
  .fields-wrap {
    overflow-x: hidden;
  }
}

app-delete-document-modal, app-add-purchasing-option-modal {
  .kehe-connect-modal .kehe-modal .footer {
    margin-top: 0 !important;
  }
}

app-ticket-reason-modal {
  .kehe-connect-modal .kehe-modal .body .alert-bar {
    margin: 20px 0 20px 0;
  }
}

app-product-quick-view-modal {
  .kehe-connect-modal .kehe-modal .body {
    margin-top: 0px !important;
  }

  .kehe-connect-modal .kehe-modal .header {
    flex-direction: column;
  }

  .kehe-connect-modal .kehe-modal .header .fa-times {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

app-tracking-numbers-modal {
  .kehe-connect-modal .kehe-modal .body {
    margin-top: 0px !important;
  }

  .kehe-connect-modal .kehe-modal .header {
    flex-direction: column;
    border: none !important;
    padding-bottom: 0px !important;
  }

  .kehe-connect-modal .kehe-modal .header .fa-times {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

app-credit-details-version-history-modal {
  .kehe-connect-modal .kehe-modal .body {
    margin-top: 0px !important;
  }
}

app-add-product-modal {
  .fields-wrap {
    width: calc(100% - 15px);
  }

  .char-length {
    float: right;
    background-color: transparent;
    font-size: 10px;
    color: #666666 !important;
  }
}
.kehe-connect-modal .kehe-modal .body .alert-bar {
  margin: 0 0 16px 0;
}

kehe-phoenix-feedback-modal .kehe-connect-modal .kehe-modal .body .alert-bar {
  margin: 0 0 0 0;
}

#document-delete-btn {
  color: $red-75;
  font-weight: $regular;
  border-color: $red-10;
}

#document-delete-btn:hover {
  background-color: #fceded;
  border-color: #f1a7aa;
}

.comment-container-wrapper div.d-flex.align-items-center:has(.btn-icon) {
  position: relative;
}

.comment-container-wrapper div.d-flex.align-items-center:has(.btn-icon)::after {
  content: "";
  background-color: $red-50;
  height: 6px;
  width: 6px;
  border-radius: 8px;
  margin-left: auto;
  position: absolute;
  bottom:10px;
  left:8px;
}

.kehe-grid {
  .item-checkbox-col {
    width: 30px;
    padding: 0px !important;
    text-align: center;
  }

  .priority .fa-flag {
    color: $orange-50;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  }
}

.k-grid .k-grid-content td.no-padding-cell {
  padding: 0 !important;
}

.k-datepicker .k-state-disabled {
  font-weight: 600 !important;
}

.kehe-grid .k-grid table th,
    .kehe-grid .k-grid table td {


      &.grid-number-center {
        text-align: center;
      }
      &.grid-number-center .k-link {
        text-align: center;
        display: block;
      }
    }

// Credit Details Overrides
.credit-details-page-wrapper {
  .credit-details-grid-wrapper {
    .k-grid .k-hierarchy-cell {
      width: 0;
      display: none;
    }
    .k-grid .k-header.k-hierarchy-cell {
      border: none;
      display: none;
    }
    .k-grid .k-hierarchy-cell .k-icon {
      display: none;
    }
    .k-grid .k-hierarchy-col {
      width: 1px;
      display: none;
    }

    .kehe-grid .k-grid table th,
    .kehe-grid .k-grid table td {


      &.shipped-qty-col {
        padding: 10px 5px;
        text-align: center;
      }

      &.item-img-col {
        padding: 7px !important;
      }
    }

    .kehe-grid .k-grid table tr.k-detail-row td.k-detail-cell {
      padding: 0px !important;
    }

    .kehe-grid .k-grid table tr.k-master-row {
      height: 100px;
    }
  }
}

.v1-navigate-alert .custom-action.desktop {
  max-width: 100% !important;
}

.cdk-drag-preview {
  // For Carousel Details darg&drop in modules/utilities/marketing/carousels/detail/components/carousel-media-reorder
  .item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
		min-height: 42px;
		max-height: 54px;

    .media-name-cell {
      min-width: 362px;
      padding-left: 2px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .media-name {
        font-weight: $OpenSansSemiBold;
      }

      .media-type {
        font-size: $font-size-small;
      }

    }

    .media-link {
      word-wrap: break-word;
			min-width: 100px;
			max-width: 674px;
      padding-right: 24px;
    }
  }

  .product-re-order-row {
    max-height: 95px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;

    a.upc {
      font-weight: $semi-bold;
      text-decoration: underline;
      color: $teal-75;
    }
  }

}

.product-category-display-names-details-component .k-grid .k-detail-row .k-detail-cell {
  padding: 0px !important;
  margin: 0px !important;
}

.kehe-nested-grid .k-grid-content {
  z-index: 0;
}

#promotions-management-list-table {
  .k-grid-table tr {
      line-height: 43px !important;
  }
}

#custom-waves-container {
  .delete-wave-btn {
    .fa-trash-alt {
      margin-right: 0px;
      color: $red-50;
      font-size: $kehe-icon-size;
    }

    button {
      margin-top: 19px;
    }
  }
}

.show-general-form {
  .form-fields-container {
    .form-group {
      kendo-formfield {
        div {
          div {
            input.is-disabled-and-selected.k-radio.ng-untouched.ng-pristine.k-radio-md {
              border: unset !important;
            }
          }
        }
      }
    }
  }
}

.roadmap-national-deals-grid-wrapper,
.bulk-edit-oi-mcb-table-grid-wrapper {
  .k-grid {
    .k-numerictextbox.text-center .k-input-inner {
      text-align: center !important;
    }

    .k-numerictextbox.text-right .k-input-inner {
      text-align: right !important;
    }

    .errorInput {
      background-color: $red-0 !important;
      border: 1px solid $red-50 !important;
    }

    .errorInput .k-numeric-wrap {
      background-color: $red-0 !important;
      border: 1px solid $red-50 !important;
    }
  }

  .k-input.k-disabled {
    &.k-input-solid .k-input-inner,
    & .k-input-inner::placeholder {
      color: $gray-50 !important;
    }
  }
}

.promotional-roadmap-nd-add-products-product-card {
  .k-checkbox-label {
    label.k-label {
      font-weight: $bold;
    }
  }
}

.promotional-roadmap-comments-section-wrapper {
  .limited-height.k-textarea {
    max-height: 80px !important;
    resize: none !important;
    min-height: 80px !important;
    textarea.k-input-inner {
      max-height: 80px !important;
      resize: none !important;
    }
  }
}

.custom-promotions-list-page {
  .kehe-grid {
    width: auto;
  }

  .grid-longtext {
    overflow-wrap: break-word;
  }
}

.promotion-upload-modal-wrapper {
  .k-label {
      display: inline;
  }

  kendo-label:not(.k-checkbox-label) label.k-label {
    font-weight: $bold;
  }
}
